.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.App-header {
  font-size: 14px;
  color: rgb(195,207,217);
}

.App-link {
  position: relative;
  color: #61dafb;
}
