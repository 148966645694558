.wrapper {
    background-color: whitesmoke;
    list-style-type: none;
    padding: 0;
    border-radius: 3px;
}
.form-row {
    display: flex;
    justify-content: flex-end;
    padding: .5em;
}

.form-row > textarea {
    flex: 1;
}

.form-row > input {
    flex: 1;
}
.form-row > input,
.form-row > button {
    padding: .5em;
}
.form-row > button {
    background: gray;
    color: white;
    border: 0;
}