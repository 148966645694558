body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282c34;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.App-header {
  font-size: 14px;
  color: rgb(195,207,217);
}

.App-link {
  position: relative;
  color: #61dafb;
}

.wrapper {
    background-color: whitesmoke;
    list-style-type: none;
    padding: 0;
    border-radius: 3px;
}
.form-row {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: .5em;
}

.form-row > textarea {
    -webkit-flex: 1 1;
            flex: 1 1;
}

.form-row > input {
    -webkit-flex: 1 1;
            flex: 1 1;
}
.form-row > input,
.form-row > button {
    padding: .5em;
}
.form-row > button {
    background: gray;
    color: white;
    border: 0;
}
